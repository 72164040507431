<template>
  <div>
    <a-button-group size="small">
      <a-button v-if="['completed', 'approving', 'approved', 'voided'].indexOf(item.status) !== -1" @click="handleDetail"
        >详情</a-button
      >
      <a-button v-if="['editing', 'rejected'].indexOf(item.status) !== -1" @click="handleEdit">编辑</a-button>
      <a-popconfirm
        v-if="['editing', 'rejected'].indexOf(item.status) !== -1"
        title="确定确认检验报告吗?"
        @confirm="handleCommit"
      >
        <a-button type="primary">确认</a-button>
      </a-popconfirm>
      <a-popconfirm v-if="['editing', 'rejected'].indexOf(item.status) !== -1" title="确定提交审批吗?" @confirm="handleSubmit">
        <a-button type="primary">提交审批</a-button>
      </a-popconfirm>
      <a-popconfirm
        v-if="['editing', 'rejected', 'voided'].indexOf(item.status) !== -1"
        title="确定删除吗?"
        @confirm="handleDelete"
      >
        <a-button type="danger">删除</a-button>
      </a-popconfirm>
      <a-popconfirm v-if="['completed', 'approved'].indexOf(item.status) !== -1" title="确定作废吗" @confirm="handleVoid">
        <a-button type="danger">作废</a-button>
      </a-popconfirm>
    </a-button-group>
  </div>
</template>

<script>
import {
  inspectionReportDestroy,
  inspectionReportCommit,
  inspectionReportSubmit,
  inspectionReportVoid,
} from "@/api/inspection";

export default {
  components: {},
  props: ["item"],
  data() {
    return {};
  },
  methods: {
    handleEdit() {
      this.$router.push({ path: "/inspection/production_inspection_report_update", query: { id: this.item.id } });
    },
    handleDetail() {
      this.$router.push({ path: "/inspection/production_inspection_report_detail", query: { id: this.item.id } });
    },
    handleCommit() {
      inspectionReportCommit({ id: this.item.id }).then((data) => {
        this.$message.success("确认成功");
        this.$emit("update", data);
      });
    },
    handleSubmit() {
      inspectionReportSubmit({ id: this.item.id }).then((data) => {
        this.$message.success("提交审批成功");
        this.$emit("update", data);
      });
    },
    handleDelete() {
      inspectionReportDestroy({ id: this.item.id }).then(() => {
        this.$message.success("删除成功");
        this.$emit("delete", this.item);
      });
    },
    handleVoid() {
      inspectionReportVoid({ id: this.item.id }).then((data) => {
        this.$message.success("作废成功");
        this.$emit("update", data);
      });
    },
  },
};
</script>

<style scoped></style>
